import React from 'react';
import { Fade } from "react-awesome-reveal";


export default function Contact() {
  return (
    <Fade>
    <h3>Contact Me:</h3>
    <h4><a href="mailto:trevorbuchanan.com">Trevor@TrevorBuchanan.com</a></h4>
    </Fade>
  );
}