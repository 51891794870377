import './App.css';
import  { Switch, Route, NavLink } from "react-router-dom";

import Contact from "./components/Contact";
import Projects from "./components/Projects";
import About from "./components/About";

function App() {
  return (
    <div className="App">
      <header>
        <nav> 
          <ul className="Navigation">
            <li>
              <NavLink exact activeClassName="selected" to="/About" >About</NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to="/Projects" >Projects</NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to="/Contact" >Contact</NavLink>
            </li>
          </ul>
        </nav>
        <h1>Trevor Buchanan</h1>
        <h2 className="AltColor" >Full Stack Developer</h2>
      </header>
      <hr />
      <section>
        <Switch>
          <Route path="/About" component={About} />
          <Route path="/Projects" component={Projects} />
          <Route path={["/", "/Contact"]} component={Contact} />
        </Switch>
      </section>
    </div>

  );
}

export default App;
