import React from 'react';
import { Fade } from 'react-awesome-reveal';

let xkcd = {
  url: "https://xkcd.trevorbuchanan.com",
  name: "xkcd Comic Viewer",
  // Images should be 300x229
  thumb: "xkcd_thumb.png",
  altText: "xkcd project thumbnail",
  summary: "Small single page project using redux and an xkcd api",
  github: "https://github.com/buchananta/React-Redux-App/tree/trevor-buchanan"
}

let ssh = {
  url: "https://ssh-attempts.trevorbuchanan.com",
  name: "ssh Attempts Map",
  thumb: "ssh_thumb.png",
  altText: "ssh-attempts project thumbnail",
  summary: "A map of geolocated ip addresses that have tried to ssh into this server",
  github: "https://github.com/buchananta/ssh-attempts",
}

let projects = [xkcd, ssh];


export default function Contact() {
  return (
    <Fade>
    <h4>Projects</h4>
    <div className="Projects">
      {
        projects.map((project, key) => (
          <div key={key} className="Project">
            <a href={project.url}>
            <h5>{project.name}</h5>
            <img src={project.thumb} alt={project.altText} />
            <p>{project.summary}</p>
            </a>
            <a href={project.github}>github</a>
          </div>
        ))
      }
    </div>
    </Fade>
  );
}
