import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const default_skills = [
  "Java",
  "Spring",
  "PostgreSQL",
  "JavaScript", 
  "React",
  "Redux",
  "HTML/CSS",
  "Rust",
  "React Testing Library",
  "Python",
  "Linux",
  "Git",
]

function makeSkillsRow(skills, key) {
  // put a divider between skills
  // returning formatted JSX
  return (
    <p key={key}> {skills
      .map((s, i, a) => {
        if (i + 1 < a.length) {
          return <span key={i}>{s} <span className="Divider" >|</span> </span>;
        } else {
          return <span key={i}>{s}</span>;
        }
      })
    } </p>
  );
}

function Skills(skills) {
  // split skills into lines, and format them into a
  // row, returning the result as an array of JSX
  let res = [];
  const maxWidth = window.innerWidth;
  let characterCount = 0;
  let start = 0;
  for (let i = 0; i < skills.length; i++) {
    characterCount += skills[i].length;
    // multiplying by 40 to roughly guess how wide
    // the text will be
    if (characterCount * 40 > maxWidth) {
      res.push(makeSkillsRow(skills.slice(start, i+1), i));
      start = i+1;
      characterCount = 0;
    }
  }
  res.push(makeSkillsRow(skills.slice(start, skills.length), skills.length));
  return res;
}

export default function Contact() {
  const [skills, setSkills] = useState(default_skills);
  
  useEffect(() => {
    // setup event listener to trigger re-render of skills list on resize
    function handleResize() {
      console.log("called resize")
      setSkills([...skills]);
    }
    window.addEventListener('resize', handleResize);
    return () => (
      window.removeEventListener('resize', handleResize)
    )
  });

  return (
    <Fade>
      <div className="Social">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://linkedin.com/in/buchanan-trevor"
        >
          <FontAwesomeIcon icon={faLinkedin} />
            LinkedIn
          </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/buchananta"
        >
          <FontAwesomeIcon icon={faGithub} />
          Github
        </a>
      </div>
      <h4>Skills</h4>
      {Skills(skills)}
      <hr />
      <div className="Endorsement">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youracclaim.com/badges/7dbe3782-f2bc-42d1-b258-2d07a22c2f0e"
        >
          <img
            className="Badge"
            src="lambda-badge-full-stack-web-red.png"
            alt="lambda badge"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youracclaim.com/badges/7dbe3782-f2bc-42d1-b258-2d07a22c2f0e"
        >Endorsed by Lambda School
          </a>
      </div>
    </Fade>
  );
}